/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link, StaticQuery, graphql } from "gatsby"

import BlogCard from "./blog-card"
import BlogData from "../util/blog.json"

export default function BlogListHome() {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { template: { eq: "blog-post" } } }
          ) {
            edges {
              node {
                id
                excerpt(pruneLength: 250)
                frontmatter {
                  date(formatString: "MMMM DD, YYYY")
                  title
                  description
                  authors
                  featuredImage {
                    childImageSharp {
                      fluid(maxWidth: 600, quality: 80) {
                        ...GatsbyImageSharpFluid
                        ...GatsbyImageSharpFluidLimitPresentationSize
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const posts = data.allMarkdownRemark.edges
          .filter(edge => !!edge.node.frontmatter.date)
          .map(edge => <BlogCard key={edge.node.id} data={edge.node} />)
        return <PostMaker data={posts} />
      }}
    />
  )
}

const PostMaker = ({ data }) => (
  <div sx={blogStyles.blogListHome}>
    <section sx={blogStyles.blogContainer}>
      <h2 sx={{ variant: "variants.pageHomeHead.title" }}>{BlogData.title}</h2>
      <p sx={{ variant: "variants.pageHomeHead.tagline" }}>
        {BlogData.description}
      </p>
      <div sx={blogStyles.blogListContainer}>{data.slice(0, 3)}</div>
      {data.length > 3 ? (
        <Link sx={blogStyles.moreButton} to="/blog">
          See all posts
          <span className="icon">&rarr;</span>
        </Link>
      ) : (
        ""
      )}
    </section>
  </div>
)

const blogStyles = {
  blogListHome: {
    maxWidth: "100%",
    variant: "variants.section",
  },
  blogContainer: {
    variant: "variants.container",
  },
  blogListContainer: {
    display: "grid",
    gridTemplateColumns: [
      "repeat(1, 1fr)",
      "repeat(2, 1fr)",
      "repeat(2, 1fr)",
      "repeat(3, 1fr)",
    ],
    gap: [7, 7, 6, 7],
    mb: 7,
  },
  moreButton: {
    variant: "variants.moreButton",
  },
}
