/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Customers = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { template: { eq: "index-page" } }) {
        id
        frontmatter {
          customers {
            customerImages {
              customerName
              customerImage {
                childImageSharp {
                  fluid(quality: 80, maxHeight: 60) {
                    ...GatsbyImageSharpFluid
                    ...GatsbyImageSharpFluidLimitPresentationSize
                  }
                  sizes {
                    src
                  }
                }
                relativePath
                publicURL
              }
            }
          }
        }
      }
    }
  `)

  const customerData = data.markdownRemark.frontmatter
  const customersLogo = customerData.customers.customerImages.map(
    (customer, index) => {
      const hasImage = customer.customerImage.childImageSharp ? true : false
      const customerImage = hasImage
        ? customer.customerImage.childImageSharp.fluid
        : ""
      const CustomerName = customer.customerName
        ? customer.customerName
        : "Image"
      return (
        <div sx={customerStyles.customerImg} key={"CImg" + index}>
          {customerImage !== "" ? (
            <Img
              fluid={customerImage}
              objectFit="cover"
              objectPosition="50% 50%"
              alt={CustomerName}
              width="640"
              height="360"
            />
          ) : (
            <div
              sx={customerStyles.logo}
              style={{
                mask:
                  "url('" +
                  customer.customerImage.publicURL +
                  "') no-repeat center / contain",
                "-webkit-mask":
                  "url('" +
                  customer.customerImage.publicURL +
                  "') no-repeat center / contain",
              }}
            />
          )}
        </div>
      )
    }
  )

  return (
    <div sx={customerStyles.customerContainer}>
      <div sx={customerStyles.customers}>
        <div sx={customerStyles.customerImages}>{customersLogo}</div>
      </div>
    </div>
  )
}

export default Customers

const customerStyles = {
  customerContainer: {
    variant: "variants.section",
    bg: "customers.bgColor",
  },
  logo: {
    opacity: "0.4",
    height: "60px",
    bg: "customers.logoColor",
    mx: [3, 0],
  },
  customers: {
    variant: "variants.container",
  },
  customerImages: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "32px",
  },
  customerImg: {
    width: "120px",
    py: 5,
    ".gatsby-image-wrapper": {
      width: "100%",
      mx: "auto",
      filter: "grayscale(1)",
      opacity: "0.9",
      textAlign: "center",
      transition: "filter .3s linear, opacity .3s linear",
    },
  },
}
