/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, navigate } from "gatsby"
import Layout from "../components/layout"
import BlogListHome from "../components/blog-list-home"
import PortfolioHome from "../components/portfolio-list-home"
import Testimonials from "../components/testimonials"
import Customers from "../components/customers"
import SEO from "../components/seo"
import { RiSendPlane2Line, RiMailFill, RiPhoneFill } from "react-icons/ri"
import { useForm, ValidationError } from '@formspree/react';


import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

export const pageQuery = graphql`
  query HomeQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        video
        homeAlign
        title
        tagLine
        description
        homeSlide {
          sliderImage {
            childImageSharp {
              fluid(quality: 80) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
              sizes {
                src
              }
            }
          }
          imageTitle
        }
        primaryCta {
          text
          url
          imageRating {
            childImageSharp {
              fluid(quality: 80) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
              sizes {
                src
              }
            }
          }
        }
        customers {
          customersVisibility
        }
        testimonialsContainer {
          testimonialVisibility
        }
        videoAreImage
      }
    }
    contactinformationdata: markdownRemark(
      frontmatter: { template: { eq: "contact-page" } }
    ) {
      id
      html
      excerpt(pruneLength: 140)
      frontmatter {
        title
        map
        contactInfo {
          contactInfoVisibility
          email
          phoneNumber
        }
        mapVisibility
      }
    }
  }
`

const HomePage = ({ data }) => {
  const { markdownRemark, contactinformationdata } = data // data.markdownRemark holds your page data
  const { frontmatter } = markdownRemark
  const frontmatterContact = contactinformationdata.frontmatter
  const htmlContact = contactinformationdata.html
  const contactDetails = frontmatterContact.contactInfo

  // const primaryUrl = frontmatter.primaryCta.url
  //   ? frontmatter.primaryCta.url
  //   : ""
  // const primaryText = frontmatter.primaryCta.text
  //   ? frontmatter.primaryCta.text
  //   : ""
  const imageRating = frontmatter.primaryCta.imageRating
    ? frontmatter.primaryCta.imageRating.childImageSharp.sizes.src
    : ""
  // console.log(imageRating);


  function ContactFormGetInTouch() {
    const [state, handleSubmit] = useForm("mdoyzlgy");
    if (state.succeeded) {
      return <p> Thank you for contacting me, I will write to you soon </p>
    }
    return (
      <div sx={contactStyles.wrapper}>
        <form
          sx={contactStyles.ContactFormGetInTouch}
          onSubmit={handleSubmit}
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="contactgetintouch" />

          <input
            id="email"
            type="email"
            name="email"
            placeholder="Enter your email"
            required
          />
          <ValidationError prefix="Email" field="email" errors={state.errors} />

          <input
            type="hidden"
            id="message"
            name="message"
            placeholder="Message"
            value="Conctac Me"
          ></input>
          <div sx={contactStyles.sendButtonGet}>
            <button
              sx={{
                variant: "variants.button.primary",
                borderRadius: "12px",
                fontWeight: "600",
                mt: 1,
                mb: 1,
              }}
              type="submit"
              disabled={state.submitting}
            >
              Get In Touch
              {/* <RiSendPlane2Line sx={{ ml: 2 }} /> */}
            </button>
          </div>
        </form>
      </div>
    )
  }

  // Featured home
  const personalInfo = (
    <div sx={indexStyles.personalDetails}>
      <p sx={indexStyles.tagLine}>{frontmatter.tagLine}</p>
      <h1 sx={indexStyles.title}>{frontmatter.title}</h1>
      <p sx={indexStyles.description}>{frontmatter.description}</p>
      <ContactFormGetInTouch />
      <div sx={indexStyles.cta}>
        {imageRating && (
          <img
          src={imageRating}
          alt="Rating Stars"
          sx={indexStyles.ratingImage}
          width="200"
          height="360"
          />
          )}
        <p sx={indexStyles.starText}> 5 / 5</p>
      </div>
      <p sx={indexStyles.starMessage}> Based on order ratings </p>
    </div>
  )

  const Homeslides = frontmatter.homeSlide.map((slide, index) => {
    const hasImage = slide.sliderImage.childImageSharp.sizes.src.includes(
      "spacer.png"
    )
      ? false
      : true
    const imageText = slide.imageTitle
      ? slide.imageTitle
      : "Front Page Personal"
    return (
      <div key={"homeSlider" + index}>
        {hasImage && (
          <img
            src={slide.sliderImage.childImageSharp.sizes.src}
            alt={imageText}
            sx={indexStyles.slideImage}
            width="640"
            height="360"
          />
        )}
      </div>
    )
  })

  const sliderShow = (
    <div sx={indexStyles.sliderShow}>
      {frontmatter.homeSlide.length === 1 ? (
        <div>{Homeslides}</div>
      ) : (
        <Carousel
          autoPlay
          infiniteLoop
          swipeable={true}
          transitionTime={300}
          showArrows={false}
          showStatus={false}
          showThumbs={false}
        >
          {Homeslides}
        </Carousel>
      )}
    </div>
  )

  const video = frontmatter.video ? frontmatter.video : ""
  const selectVideoAreImage = (
    <div className="sliderImage">
      {frontmatter.videoAreImage === "Image" ? sliderShow : ""}
      {frontmatter.videoAreImage === "Video" ? (
        <div dangerouslySetInnerHTML={{ __html: video }} />
      ) : (
        ""
      )}
      {frontmatter.videoAreImage === "None" ? "" : ""}
    </div>
  )

  function ContactForm() {
    const [state, handleSubmit] = useForm("mdoyzlgy");
    if (state.succeeded) {
      navigate(`/thanks`)
    }
    return (
      <div sx={contactStyles.wrapper}>
        <form
          sx={contactStyles.contactForm}
          onSubmit={handleSubmit}
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="contact" />
          <p>
            <input type="text" name="name" placeholder="Name" required />
          </p>
          <p>
            <input id="email" type="email" name="email" placeholder="Email" required />
            <ValidationError 
              prefix="Email" 
              field="email"
              errors={state.errors}
            />
          </p>
          <p>
            <input type="text" name="subject" placeholder="Subject" required />
          </p>
          <div sx={contactStyles.wrap}>
            <div>
              <textarea  id="message" name="message" placeholder="Message" required></textarea>
              <ValidationError 
                prefix="Message" 
                field="message"
                errors={state.errors}
              />
              <div sx={contactStyles.sendButton}>
                <button
                  sx={{
                    variant: "variants.button.primary",
                    borderRadius: "12px",
                  }}
                  type="submit"
                  disabled={state.submitting}
                >
                  Send Message
                  <RiSendPlane2Line sx={{ ml: 2 }} />
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }

  const contactInformation = (
    <div sx={contactStyles.contactInformation}>
      <div>
        <h2 sx={contactStyles.title}>{frontmatterContact.title}</h2>
        <div
          sx={contactStyles.description}
          dangerouslySetInnerHTML={{ __html: htmlContact }}
        />
      </div>
      {frontmatterContact.contactInfo.contactInfoVisibility === "YES" ? (
        <div>
          {contactDetails.email ? (
            <a
              href={"mailto:" + contactDetails.email}
              sx={contactStyles.infoAction}
            >
              <div sx={contactStyles.contactDetail}>
                <div>
                  <RiMailFill sx={contactStyles.infoIcon} />
                </div>
                <p sx={contactStyles.contactInfo}>{contactDetails.email}</p>
              </div>
            </a>
          ) : (
            ""
          )}

          {contactDetails.phoneNumber ? (
            <a
              href={"tel:" + contactDetails.phoneNumber}
              sx={contactStyles.infoAction}
            >
              <div sx={contactStyles.contactDetail}>
                <div>
                  <RiPhoneFill sx={contactStyles.infoIcon} />
                </div>
                <p sx={contactStyles.contactInfo}>
                  {contactDetails.phoneNumber}
                </p>
              </div>
            </a>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  )

  // console.log("frontmatter.homeSlide")
  // console.log(frontmatter.homeSlide[0].sliderImage.childImageSharp.sizes.src)

  return (
    <Layout>
      <SEO
        image={frontmatter.homeSlide[0].sliderImage.childImageSharp.sizes.src}
        description={frontmatter.description}
        title={frontmatter.title + " " + frontmatter.tagLine}
      />
      <div sx={indexStyles.homeBanner}>
        {frontmatter.homeAlign === "right" ? (
          <div sx={indexStyles.homeBannerLeft}>
            <div sx={indexStyles.orderOne}>{selectVideoAreImage}</div>
            <div sx={indexStyles.orderTwo}>{personalInfo}</div>
          </div>
        ) : (
          ""
        )}
        {frontmatter.homeAlign === "left" ? (
          <div sx={indexStyles.homeBannerRight}>
            {personalInfo}
            {selectVideoAreImage}
          </div>
        ) : (
          ""
        )}
        {frontmatter.homeAlign === "center" ? (
          <div sx={indexStyles.homeBannerCenter}>
            {personalInfo}
            {selectVideoAreImage}
          </div>
        ) : (
          ""
        )}
      </div>
      {frontmatter.customers.customersVisibility === "YES" ? <Customers /> : ""}
      <PortfolioHome />
      {frontmatter.testimonialsContainer.testimonialVisibility === "YES" ? (
        <Testimonials />
      ) : (
        ""
      )}
      <BlogListHome />
      <div sx={contactStyles.contactBody}>
        <div sx={contactStyles.contactContainer}>
          {contactInformation}
          <ContactForm />
        </div>
      </div>
    </Layout>
  )
}

export default HomePage

const indexStyles = {
  homeBanner: {
    py: [5, 5, 6, 7, "auto"],
    m: "0 auto",
    bg: "home.background",
    width: ["100%", "100%", "100%", "100%", "100%"],
  },
  homeBannerLeft: {
    ".slide": {
      bg: "transparent",
    },
    ".control-dots": {
      ".dot": {
        boxShadow: "none !important",
        width: "16px",
        height: "16px",
      },
    },
    variant: "variants.container",
    display: "grid",
    gridTemplateColumns: ["1fr", "1fr", "1fr", "1fr 1fr", "1fr 2fr"],
    px: ["20px", "20px", "40px", "40px", "80px"],
    alignItems: "center",
    justifyContent: "center",
    gap: 7,
  },
  orderOne: {
    order: ["2", "1"],
  },
  orderTwo: {
    order: ["1", "2"],
  },
  homeBannerRight: {
    ".slide": {
      bg: "transparent",
    },
    ".control-dots": {
      ".dot": {
        boxShadow: "none !important",
        width: "16px",
        height: "16px",
      },
    },
    variant: "variants.container",
    display: "grid",
    gridTemplateColumns: ["1fr", "1fr", "1fr", "1fr 1fr", "1fr 1fr"],
    px: ["20px", "20px", "40px", "40px", "80px", "80px"],
    alignItems: "center",
    justifyContent: "center",
    gap: 7,
  },
  homeBannerCenter: {
    variant: "variants.container",
    px: ["20px", "20px", "40px", "40px", "80px"],
    textAlign: "center",
    p: {
      m: "0 auto",
      pt: 2,
    },
    ".sliderImage": {
      m: "auto 0px",
      mt: [0, 0, 7],
      maxWidth: "100%",
      width: "100%",
    },
  },
  sliderShow: {
    borderRadius: "12px",
    overflow: "hidden",
  },
  slideImage: {
    width: "100%",
    height: "auto",
    maxWidth: "100%",
    display: "block",
  },
  ratingImage: {
    width: "48%",
    height: "auto",
    maxWidth: "124px",
    display: "inline-block",
    mb: "0",
    mt: "0",
  },
  starText: {
    display: "inline-block",
    fontWeight: "800",
    ml: "10px",
    fontSize: "28px",
    color: "#7f7f7f",
    mb: "0",
    mt: "0",
  },
  starMessage: {
    width: "100%",
    display: "block",
    mt: "0px",
    color: "#ababab",
    fontWeight: "500",
    fontSize: "16px",
    ml: "18px",
  },
  personalDetails: {
    maxWidth: "100%",
  },
  title: {
    m: 0,
    mb: [3, 4],
    p: 0,
    color: "home.headingColor",
    letterSpacing: "1px",
    fontSize: [
      "32px !important",
      "38px !important",
      "48px !important",
      "48px !important",
      "48px !important",
    ],
  },
  tagLine: {
    mt: 2,
    mb: [1],
    fontSize: 3,
    color: "home.tagColor",
  },
  description: {
    m: 0,
    lineHeight: "1.6",
    maxWidth: "75ch",
    color: "home.textColor",
    pb: [3, 4],
    fontSize: [4],
  },
  cta: {
    mt: "24px",
    mb: 0,
    ml: "24px",
    display: "flex",
    alignItems: "center"
  },
}

const contactStyles = {
  contactPage: {
    variant: "variants.section",
    pb: 0,
    minHeight: "75vh",
  },
  contactBody: {
    variant: "variants.container",
  },
  contactContainer: {
    display: "grid",
    gridTemplateColumns: ["1fr", "1fr", "1fr", "1fr 1fr"],
    gap: ["30px", "60px"],
    alignItems: "center",
    maxWidth: "1280px",
    m: "0 auto",
    mb: 7,
  },
  infoAction: {
    display: "block",
    mt: 5,
  },
  wrapper: {
    maxWidth: "100%",
  },
  title: {
    m: 0,
    color: "global.headingColor",
    fontSize: [
      "32px !important",
      "48px !important",
      "48px !important",
      "64px !important",
    ],
  },
  description: {
    display: "inline-block",
    m: 0,
    fontSize: [3, 4],
    color: "global.mutedColor",
    fontWeight: "light",
  },
  ContactFormGetInTouch: {
    display: "flex", 
    alignItems: "center",
    alignContent: "center",
    input: {
      width: "70%",
      maxWidth: "70%",
      mb: 0,
      mt: 0,
      mr: 1,
      p: "16px",
      bg: "contact.inputBgColor",
      color: "contact.inputTextColor",
      border: "2px solid",
      borderColor: "contact.borderColor",
      borderRadius: "10px",
      appearance: "none",
      fontSize: "16px",
      outline: "none",
      "&:focus ": {
        borderColor: "contact.inputFocusColor",
      },
    },
  },
  sendButtonGet: {
    mt: 0,
  },
  contactForm: {
    p: {
      m: 0,
    },
    input: {
      width: "100%",
      maxWidth: "100%",
      mb: 5,
      p: "16px",
      bg: "contact.inputBgColor",
      color: "contact.inputTextColor",
      border: "2px solid",
      borderColor: "contact.borderColor",
      borderRadius: "10px",
      appearance: "none",
      fontSize: "16px",
      outline: "none",
      "&:focus ": {
        borderColor: "contact.inputFocusColor",
      },
    },
    textarea: {
      minHeight: "200px",
      fontFamily: "inherit",
      width: "100%",
      maxWidth: "100%",
      p: "16px",
      bg: "contact.inputBgColor",
      color: "contact.inputTextColor",
      border: "2px solid",
      borderColor: "contact.borderColor",
      borderRadius: "10px",
      appearance: "none",
      fontSize: "16px",
      outline: "none",
      display: "block",
      "&:focus ": {
        borderColor: "contact.inputFocusColor",
      },
    },
  },
  contactInformation: {
    bg: "contact.infoBgColor",
    borderRadius: "12px",
    border: "2px solid",
    borderColor: "contact.borderColor",
    p: 4,
    alignSelf: "normal",
  },
  contactDetail: {
    display: ["block", "flex", "flex", "flex"],
    alignItems: "top",
  },
  infoIcon: {
    fontSize: 6,
    color: "contact.textColor",
    mr: 3,
  },
  contactInfo: {
    fontSize: 3,
    m: 0,
    color: "contact.textColor",
  },
  map: {
    maxWidth: "1280px",
    m: "0 auto",
    mb: 7,
    borderColor: "contact.borderColor",
    iframe: {
      mb: "-7px",
      border: "1px solid",
      borderRadius: "12px",
      overflow: "hidden",
    },
  },
  sendButton: {
    mt: 5,
  },
  button: {
    color: "button.primaryTextColor",
    bg: "button.primaryTextHoverColor",
    ":hover": {
      color: "button.primaryBgColor",
      bg: "button.primaryBgHoverColor",
    },
  },
  wrap: {
    position: "relative",
    textarea: {
      mb: 0,
    },
  },
}
