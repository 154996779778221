/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, useStaticQuery } from "gatsby"
import { RiChatQuoteFill } from "react-icons/ri"
import Img from "gatsby-image"

import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Carousel } from "react-responsive-carousel"

const Testimonials = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(frontmatter: { template: { eq: "index-page" } }) {
        id
        frontmatter {
          testimonialsContainer {
            testimonials {
              description
              name
              jobRole
              image {
                childImageSharp {
                  fluid(maxWidth: 900, quality: 80) {
                    ...GatsbyImageSharpFluid
                    ...GatsbyImageSharpFluidLimitPresentationSize
                  }
                  sizes {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const testimonialData = data.markdownRemark.frontmatter
  const testimonial = testimonialData.testimonialsContainer.testimonials.map(
    (item, index) => {
      const imageTestimonial = item.image
        ? item.image.childImageSharp.fluid
        : ""
      return (
        <div key={"testimonial" + index} sx={testimonialsStyles.testimonials}>
          {item.image ? (
            <Img
              width="640"
              height="360"
              fluid={imageTestimonial}
              objectFit="cover"
              objectPosition="100% 100%"
              alt={item.name + " - Featured image"}
              sx={testimonialsStyles.featuredImage}
            />
          ) : (
            <div>
              <RiChatQuoteFill sx={testimonialsStyles.quoteicon} />
            </div>
          )}
          <div sx={testimonialsStyles.testimonialDetails}>
            {item.description ? (
              <p sx={testimonialsStyles.description}>{item.description}</p>
            ) : (
              ""
            )}

            {item.name || item.jobRole ? (
              <div sx={testimonialsStyles.testimonialData}>
                {item.name ? <h3>{item.name}</h3> : ""}
                {item.jobRole ? <span>{item.jobRole}</span> : ""}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      )
    }
  )

  return (
    <div sx={testimonialsStyles.testimonial}>
      <Carousel
        autoPlay
        infiniteLoop
        transitionTime={300}
        showStatus={false}
        showThumbs={false}
        showIndicators={false}
        showArrows={true}
      >
        {testimonial}
      </Carousel>
    </div>
  )
}

export default Testimonials

const testimonialsStyles = {
  testimonial: {
    maxWidth: "100%",
    width: "100%",
    variant: "variants.section",
    bg: "testimonials.bgColor",
    ".slide": {
      bg: "transparent",
      textAlign: "left",
    },
    ".carousel .control-arrow.control-prev, .carousel .control-arrow.control-next": {
      bottom: "inherit",
      left: "inherit",
      right: "inherit",
      top: "0",
      opacity: "0.5 !important",
      "&:hover": {
        opacity: "1 !important",
      },
    },
    ".carousel .control-arrow.control-prev": {
      right: "60px",
    },
    ".carousel .control-arrow.control-next": {
      right: "30px",
    },
    ".carousel .control-prev.control-arrow::before": {
      borderRight: "8px solid",
      borderColor: "testimonials.logoColor",
      borderTopColor: "transparent",
      borderBottomColor: "transparent",
    },
    ".carousel .control-next.control-arrow::before": {
      borderLeft: "8px solid",
      borderColor: "testimonials.logoColor",
      borderTopColor: "transparent",
      borderBottomColor: "transparent",
    },
    ".carousel.carousel-slider .control-arrow:hover": {
      bg: "transparent",
    },
    ".carousel .control-arrow, .carousel.carousel-slider .control-arrow": {
      opacity: 0.8,
      "&:hover": {
        opacity: 1,
      },
    },
  },
  testimonials: {
    variant: "variants.container",
    px: ["20px", "20px", "40px", "40px", "80px"],
    display: "grid",
    gridTemplateColumns: ["1fr", "1fr", "1fr 2fr"],
    alignItems: "center",
    overflow: "hidden",
  },
  testimonialDetails: {
    maxWidth: "100%",
    overflow: "hidden",
    ml: [0, 0, 0, 7, 7],
  },
  quoteicon: {
    fontSize: ["200px", "300px", "300px", "400px"],
    color: "testimonials.logoColor",
    display: "inherit",
    ml: ["-20px", "-30px", "-30px", "-40px"],
    mr: 5,
    overflow: "hidden",
  },
  featuredImage: {
    maxWidth: ["60%", "50%", "85%", "100%", "100%"],
    maxHeight: "500px",
    width: "333px",
    display: "block",
    m: 1,
    mx: "auto",
    borderRadius: "12px",
    border: "1px solid",
    borderColor: "global.borderColor",
  },
  description: {
    maxWidth: "75ch",
    fontSize: [3, 3, 3, 4, 5],
    fontWeight: "light",
    lineHeight: 1.6,
    display: "block",
    color: "testimonials.textColor",
    ml: 0,
    mr: 5,
    mt: 4,
    pb: 4,
  },
  testimonialData: {
    textTransform: "capitalize",
    h3: {
      fontSize: 4,
      my: 0,
      mt: [2, 3, 4],
      mb: 1,
      color: "global.headingColor",
    },
    span: {
      mt: 1,
      fontSize: 1,
      color: "global.mutedColor",
    },
  },
}
