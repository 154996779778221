/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link, StaticQuery, graphql } from "gatsby"

import PortfolioCard from "../components/portfolio-card"

export default function PortfolioListHome() {
  return (
    <StaticQuery
      query={graphql`
        query {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { template: { eq: "portfolio-page" } } }
          ) {
            edges {
              node {
                id
                excerpt(pruneLength: 250)
                frontmatter {
                  title
                  date(formatString: "MMMM DD, YYYY")
                  description
                  align
                  featuredImage {
                    childImageSharp {
                      fluid(maxWidth: 900, quality: 80) {
                        ...GatsbyImageSharpFluid
                        ...GatsbyImageSharpFluidLimitPresentationSize
                      }
                      sizes {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        const portfolio = data.allMarkdownRemark.edges
          .filter(edge => !!edge.node.frontmatter.date)
          .map(edge => <PortfolioCard key={edge.node.id} data={edge.node} />)
        return <PostMaker data={portfolio} />
      }}
    />
  )
}

const PostMaker = ({ data }) => (
  <div sx={portfolioStyles.portfolioContainer}>
    <section sx={portfolioStyles.portfolios}>
      <div>{data.slice(0, 3)}</div>
      {data.length > 3 ? (
        <Link sx={portfolioStyles.moreButton} to="/portfolio">
          See all projects
          <span className="icon">&rarr;</span>
        </Link>
      ) : (
        ""
      )}
    </section>
  </div>
)

const portfolioStyles = {
  portfolioContainer: {
    variant: "variants.section",
  },
  portfolios: {
    variant: "variants.container",
  },
  moreButton: {
    variant: "variants.moreButton",
  },
}
